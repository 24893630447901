<template>
  <UnitsOfMeasureTable/>
</template>
<script>
import UnitsOfMeasureTable from '@/views/ziqni/settings/units-of-measure/UnitsOfMeasureTable.vue';

export default {
  components: {
    UnitsOfMeasureTable
  },
  props: {}
};
</script>