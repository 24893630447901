import { render, staticRenderFns } from "./UnitsOfMeasureTable.vue?vue&type=template&id=6478c40f"
import script from "./UnitsOfMeasureTable.vue?vue&type=script&lang=js"
export * from "./UnitsOfMeasureTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports